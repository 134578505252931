import { Helmet, HelmetProvider } from "react-helmet-async";
import { FetchIHistorys } from "../modules/ihistory/index";

export default function IHistoryPage() {
  return (
    <section>
      <HelmetProvider>
        <Helmet>
          <title>история получения пластин</title>
        </Helmet>
      </HelmetProvider>

      <FetchIHistorys />
    </section>
  );
}
