import GetCookie from "../../../utils/coockies";
import { BaseAPIUrl } from "../../../config/config";

/**
 * Создает новый инструмент на сервере.
 *
 * @param {Object} tool - Объект, содержащий данные нового инструмента.
 * @param {string} tool.brand - Бренд инструмента.
 * @param {string} tool.tool_name - Название инструмента.
 * @param {string} tool.type_tool - Тип инструмента.
 * @param {string} tool.construction - Конструкция инструмента.
 * @param {number} tool.count - Общее количество инструмента.
 * @param {number} tool.used_count - Количество использованных инструментов.
 * @param {number} tool.need_count - Необходимое количество инструментов.
 * @param {string} tool.about_tool - Описание инструмента.
 * @param {string} tool.diameter - Диаметр инструмента.
 * @param {number} tool.cutting_length - Длина режущей части инструмента.
 * @param {string} tool.processing_material - Материал обработки.
 * @returns {Promise<Array>} Массив, содержащий ошибку (если есть) и данные ответа.
 */

export async function queryCerateTools(tool) {
  const token = GetCookie("token");

  let err = null;
  let responseData = null;

  let newTool = {
    tools: [
      {
        brand: tool.brand.trim(),
        tool_name: tool.tool_name.trim(),
        type_tool: tool.type_tool.trim(),
        construction: tool.construction.trim(),
        count: parseInt(tool.count, 10),
        used_count: parseInt(tool.need_count, 10),
        need_count: parseInt(tool.used_count, 10),
        about_tool: tool.about_tool.trim(),
        diameter: tool.diameter.trim(),
        cutting_length: parseFloat(tool.cutting_length),
        processing_material: tool.processing_material.trim(),
      },
    ],
  };

  try {
    const url = `${BaseAPIUrl}api/tools/`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: token,
      },
      body: JSON.stringify(newTool),
    });

    const data = response.ok ? await response.json() : null;

    switch (response.status) {
      case 200:
        err = data.err;
        for (const row of data.tools) {
          responseData = row;
        }
        break;
      case 401:
        err = "пользователь неавторизирован";
        break;
      case 400:
        err = "неправильный запрос";
        break;
      case 500:
        err = "ошибка сервера";
        break;
      default:
        err = `код ответа: ${response.status}`;
    }
  } catch (error) {
    err = error;
  }

  return [err, responseData];
}
