import { Helmet, HelmetProvider } from "react-helmet-async";
import { FetchInserts } from "../modules/inserts/index";

export default function InsertsPage() {
  return (
    <section>
      <HelmetProvider>
        <Helmet>
          <title>список пластин</title>
        </Helmet>
      </HelmetProvider>

      <FetchInserts />
    </section>
  );
}
