import { createPortal } from "react-dom";
import { useRef, useEffect } from "react";
import "./Modal.css";

export default function Modal({ children, open }) {
  const dialog = useRef();

  useEffect(() => {
    if (open) {
      dialog.current.showModal();
      document.body.classList.add("locked");
    } else {
      dialog.current.close();
      document.body.classList.remove("locked");
    }
  }, [open]);

  return createPortal(
    <dialog ref={dialog}>{children}</dialog>,
    document.getElementById("modal")
  );
}
