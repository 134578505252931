/**
 * Получает значение указанного cookie.
 *
 * @param {string} name - Имя cookie, которое нужно получить.
 * @returns {string|null} - Значение cookie, если найдено, иначе null.
 *
 * Эта функция ищет среди cookies документа cookie с указанным именем.
 * Она удаляет начальные пробелы и проверяет, начинается ли cookie с
 * данного имени, за которым следует знак равенства. Если найдено,
 * возвращает значение cookie; в противном случае возвращает null.
 */
export default function GetCookie(name) {
  const nameEQ = name + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

// // Пример использования
// const token = getCookie("token");
// console.log("Token---:", token);
