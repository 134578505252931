/**
 * Функция validationTool проверяет инструмент на соответствие определенным критериям.
 * @param {Object} tool - Объект, представляющий инструмент.
 * @param {string} tool.id - Уникальный идентификатор инструмента.
 * @param {string} tool.brand - Бренд инструмента.
 * @param {string} tool.tool_name - Идентификатор инструмента.
 * @param {string} tool.type_tool - Тип инструмента (например, MILL).
 * @param {string} tool.construction - Конструкция инструмента (например, монолит).
 * @param {number} tool.count - Общее количество инструментов.
 * @param {number} tool.used_count - Количество использованных инструментов.
 * @param {number} tool.need_count - Необходимое количество инструментов.
 * @param {string} tool.about_tool - Комментарий о инструменте.
 * @param {string} tool.diameter - Диаметр инструмента.
 * @param {number} tool.cutting_length - Длина инструмента.
 * @param {string} tool.processing_material - Обрабатываемый материал (например, P).
 * @param {string} tool.registration_date - Дата регистрации инструмента.
 * @param {string} tool.update_date - Дата последнего обновления информации об инструменте.
 * @returns {boolean} - Возвращает true, если инструмент соответствует критериям.
 */
export function toolValidation(tool, originalTool) {
  if (
    tool.tool_name.trim() === "" ||
    tool.diameter.trim() === "" ||
    tool.type_tool.trim() === ""
  ) {
    return false;
  }

  if (originalTool !== null) {
    if (
      tool.brand.trim() === originalTool.brand.trim() &&
      tool.tool_name.trim() === originalTool.tool_name.trim() &&
      tool.type_tool.trim() === originalTool.type_tool.trim() &&
      tool.construction.trim() === originalTool.construction.trim() &&
      tool.count.toString().trim() === originalTool.count.toString().trim() &&
      tool.used_count.toString().trim() ===
        originalTool.used_count.toString().trim() &&
      tool.need_count.toString().trim() ===
        originalTool.need_count.toString().trim() &&
      tool.about_tool.trim() === originalTool.about_tool.trim() &&
      tool.diameter.trim() === originalTool.diameter.trim() &&
      tool.cutting_length.toString().trim() === originalTool.cutting_length.toString().trim() &&
      tool.processing_material.trim() ===
        originalTool.processing_material.trim()
    ) {
      return false;
    }
  }

  return true;
}

// ///////////////////////////

// /**
//  * Сравнивает два объекта на глубокое равенство.
//  *
//  * @param {Object} obj1 - Первый объект для сравнения.
//  * @param {Object} obj2 - Второй объект для сравнения.
//  * @returns {boolean} - Возвращает true, если объекты равны, иначе false.
//  */
// function deepEqual(obj1, obj2) {
//   if (obj1 === obj2) {
//     return true;
//   }

//   if (
//     typeof obj1 !== "object" ||
//     obj1 === null ||
//     typeof obj2 !== "object" ||
//     obj2 === null
//   ) {
//     return false;
//   }

//   let keys1 = Object.keys(obj1);
//   let keys2 = Object.keys(obj2);

//   if (keys1.length !== keys2.length) {
//     return false;
//   }

//   for (let key of keys1) {
//     if (
//       !keys2.includes(key) ||
//       !deepEqual(trimString(obj1[key]), trimString(obj2[key]))
//     ) {
//       return false;
//     }
//   }

//   return true;
// }

// /**
//  * Удаляет пробелы в начале и конце строки.
//  *
//  * @param {any} value - Значение для обработки.
//  * @returns {any} - Возвращает обработанное значение.
//  */
// function trimString(value) {
//   return typeof value === "string" ? value.trim() : value;
// }

// //////////////////////////////////

// // возвращает варианты ответа
// export function toolOptions(dataTools, column) {
//   return [];
// }

// //////////////////////////////////

// // проверка инструмента на наличие дублирования
// export function toolDuplicationСheck(dataTools, column) {
//   return [];
// }
