import { useState, useEffect } from "react";
import InsertForm from "./insertForm";

export default function UpdateInsert(data) {
  function handleUpdateChange(event) {
    let currentTool = {
      brand: data.row.brand,
      insert_name: data.row.insert_name,
      type_insert: data.row.type_insert,
      count: data.row.count,
      used_count: data.row.used_count,
      need_count: data.row.need_count,
      about_insert: data.row.about_insert,
      radius: data.row.radius,
      cutting_length: data.row.cutting_length,
      processing_material: data.row.processing_material,
    };

    currentTool[event.target.id] = event.target.value;

    data.setCurrentRow((prev) => ({
      ...prev,
      brand: currentTool.brand,
      insert_name: currentTool.insert_name,
      type_insert: currentTool.type_insert,
      count: currentTool.count,
      used_count: currentTool.used_count,
      need_count: currentTool.need_count,
      about_insert: currentTool.about_insert,
      radius: currentTool.radius,
      cutting_length: currentTool.cutting_length,
      processing_material: currentTool.processing_material,
    }));
  }

  const [options, setOptions] = useState({
    brand: [],
    insert_name: [],
    type_insert: [],
    count: [],
    used_count: [],
    need_count: [],
    about_insert: [],
    radius: [],
    cutting_length: [],
    processing_material: [],
  });

  function getUniqueFieldValues(fieldName) {
    let filteredTools = data.dataTools;

    let fieldValues = [];
    for (const tool of filteredTools) {
      fieldValues.push(tool[fieldName]);
    }
    return [...new Set(fieldValues)];
  }

  useEffect(() => {
    setOptions((prev) => ({
      ...prev,
      brand: getUniqueFieldValues("brand"),
      insert_name: getUniqueFieldValues("insert_name"),
      typetype_insert_tool: getUniqueFieldValues("type_insert"),
      radius: getUniqueFieldValues("radius"),
      processing_material: getUniqueFieldValues("processing_material"),
    }));
  }, [data.row, data.modal]);

  if (!data.modal) {
    return <></>;
  }

  ////////////////////////////////////////////////////////////////////////

  return (
    <section>
      <h3>Редактирование инструмента</h3>
      <InsertForm
        formState={data.row}
        optionsState={options}
        handle={handleUpdateChange}
      ></InsertForm>
    </section>
  );
}
