import GetCookie from "../../../utils/coockies";
import { BaseAPIUrl } from "../../../config/config";

/**
 * Удаляет инструмент с сервера по его идентификатору.
 *
 * @param {string} id - Идентификатор инструмента.
 * @returns {Promise<string|null>} Ошибка (если есть) или null, если удаление прошло успешно.
 */

export async function queryDeleteInserts(id) {
  let err = null;
  const token = GetCookie("token");

  try {
    const params = new URLSearchParams({ id: id });
    const url = `${BaseAPIUrl}api/insert/?${params.toString()}`;

    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Token: token,
      },
    });

    const data = response.ok ? await response.json() : null;

    switch (response.status) {
      case 200:
        err = data.err;
        break;
      case 401:
        err = "пользователь неавторизирован";
        break;
      case 400:
        err = "неправильный запрос";
        break;
      case 500:
        err = "ошибка сервера";
        break;
      default:
        err = `код ответа: ${response.status}`;
    }
  } catch (error) {
    err = error;
  }
  return err;
}
