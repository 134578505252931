import { format } from "date-fns";
import { v4 as uuidv4 } from "uuid";
import { useEffect, useState, useRef } from "react";
import { SaveFilterCookies, GetFilterCookies } from "./components/cookies";
import { queryFetchTHistorys } from "./api/fetchIHistoryData";
import { UseCtrlZButton } from "./hooks/useCtrlZButton";
import Notification from "../../components/notification/Notification";
// import { UseVisibleRows } from "./hooks/useVisibleRows";

export function FetchIHistorys() {
  const [loading, setLoading] = useState(false);
  const [noCotent, setNoCotent] = useState(false);
  const [dataToolsCopy, setDataToolsCopy] = useState([]);
  const [dataTools, setDataTools] = useState([]);
  const [sort, setSort] = useState({
    sortColumnFlag: "registration_date",
    sortDirFlag: false,
  });

  const cooliesFilters = GetFilterCookies();
  const [isHoveredFilter, setIsHoveredFilter] = useState(
    cooliesFilters.registration_date !== "" ||
      cooliesFilters.username !== "" ||
      cooliesFilters.show_status !== "" ||
      cooliesFilters.count !== "" ||
      cooliesFilters.used_count !== "" ||
      cooliesFilters.about_history !== "" ||
      cooliesFilters.brand !== "" ||
      cooliesFilters.insert_name !== "" ||
      cooliesFilters.type_insert !== "" ||
      cooliesFilters.about_insert !== "" ||
      cooliesFilters.radius !== "" ||
      cooliesFilters.cutting_length !== "" ||
      cooliesFilters.processing_material !== ""
  );
  const [filter, setFilter] = useState({
    registration_date: cooliesFilters.registration_date,
    username: cooliesFilters.username,
    show_status: cooliesFilters.show_status,
    count: cooliesFilters.count,
    used_count: cooliesFilters.used_count,
    about_history: cooliesFilters.about_history,
    brand: cooliesFilters.brand,
    insert_name: cooliesFilters.insert_name,
    type_insert: cooliesFilters.type_insert,
    about_insert: cooliesFilters.about_insert,
    radius: cooliesFilters.radius,
    cutting_length: cooliesFilters.cutting_length,
    processing_material: cooliesFilters.processing_material,
  });

  const [panel, setPanel] = useState({
    isActivHour: false,
    isActivDay: false,
  });
  const [panelChanged, setPanelChanged] = useState(false);

  const timerRef = useRef(null);

  const handleMouseLeave = () => {
    timerRef.current = setTimeout(() => {
      setIsHoveredFilter(false);
    }, 2000);
  };

  const handleMouseEnter = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  };

  const [dataNotification, setDataNotification] = useState({
    children: null,
    id: null,
    color: "red",
  });

  /**
   * Обновляет данные уведомления новыми значениями.
   *
   * @param {React.ReactNode} children - Контент, который будет отображаться в уведомлении.
   * @param {string} color - Цвет уведомления.
   */
  function handleNotification(children, color) {
    setDataNotification({
      children: children,
      id: uuidv4(),
      color: color,
    });
  }

  /**
   * Обрабатывает изменение фильтра и обновляет отфильтрованный список инструментов.
   *
   * @param {Object} event - Событие, вызванное изменением фильтра.
   */
  function handleFilterChange(event) {
    let filteredList = filterChange(
      event.target.id,
      event.target.value,
      dataTools
    );
    setDataToolsCopy(filteredList);
  }

  /**
   * Функция для сортировки списка данных по указанному столбцу.
   *
   * Функция выполняет следующие действия:
   * 1. Определяет направление сортировки (по возрастанию или убыванию) в зависимости от текущего состояния сортировки.
   * 2. Обновляет состояние сортировки с новыми значениями столбца и направления.
   * 3. Сортирует копию данных (`dataToolsCopy`) по указанному столбцу и направлению.
   *
   * @param {string} sortColumnFlag - Название столбца, по которому необходимо выполнить сортировку.
   * @returns {void}
   */
  function sortDataList(sortColumnFlag) {
    let sortDirFlag = true;
    if (sort.sortColumnFlag === sortColumnFlag) {
      sortDirFlag = true;
      if (sort.sortDirFlag === true) {
        sortDirFlag = false;
      }
    }

    setSort(() => ({
      sortDirFlag: sortDirFlag,
      sortColumnFlag: sortColumnFlag,
    }));

    setDataToolsCopy(
      dataToolsCopy.sort(function (a, b) {
        let sortValue = a[sortColumnFlag] < b[sortColumnFlag];
        if (sortDirFlag === false)
          sortValue = a[sortColumnFlag] > b[sortColumnFlag];
        return sortValue ? -1 : 1;
      })
    );
  }

  useEffect(() => {
    setLoading(true);
    (async () => {
      let [err, data] = await queryFetchTHistorys();

      if (err !== null) {
        handleNotification(err, "red");
        setLoading(false);
        setNoCotent(true);
      } else if (data.length === 0) {
        setNoCotent(true);
        setLoading(false);
        handleNotification("У вас еще нет истории пластин", "yellow");
      } else {
        const updatedData = data.map((item) => {
          const utcDate = new Date(item.registration_date);
          const localDate = new Date(utcDate.toLocaleString());
          return {
            ...item,
            show_status: item.status === "get" ? "получил" : "сдал",
            registration_date: localDate,
          };
        });

        setDataTools(
          updatedData.sort(function (a, b) {
            let sortValue = a[sort.sortColumnFlag] < b[sort.sortColumnFlag];
            if (sort.sortDirFlag === false)
              sortValue = a[sort.sortColumnFlag] > b[sort.sortColumnFlag];
            return sortValue ? -1 : 1;
          })
        );
        setLoading(false);
      }
    })();
  }, []);

  /**
   * Применяет все фильтры к списку инструментов и возвращает отфильтрованный список.
   *
   * @param {Array} list - Список инструментов для фильтрации.
   * @returns {Array} - Отфильтрованный список инструментов.
   */
  function applyAllFilters(list) {
    let filteredList = [...list];
    for (let key in filter) {
      if (filter[key] !== "") {
        filteredList = filterChange(key, filter[key], filteredList);
      }
    }
    return filteredList;
  }

  /**
   * Обновляет состояние фильтра и возвращает отфильтрованный список инструментов.
   *
   * @param {string} key - Ключ фильтра, который нужно обновить.
   * @param {string} value - Новое значение для указанного ключа фильтра.
   * @param {Array} list - Список инструментов для фильтрации.
   * @returns {Array} - Отфильтрованный список инструментов.
   */
  function filterChange(key, value, list) {
    let filters = {
      registration_date: filter.registration_date,
      username: filter.username,
      show_status: filter.show_status,
      count: filter.count,
      used_count: filter.used_count,
      about_history: filter.about_history,
      brand: filter.brand,
      insert_name: filter.insert_name,
      type_insert: filter.type_insert,
      about_insert: filter.about_insert,
      radius: filter.radius,
      cutting_length: filter.cutting_length,
      processing_material: filter.processing_material,
    };

    filters[key] = value;

    setFilter((prev) => ({
      ...prev,
      registration_date: filters.registration_date,
      username: filters.username,
      show_status: filters.show_status,
      count: filters.count,
      used_count: filters.used_count,
      about_history: filters.about_history,
      brand: filters.brand,
      insert_name: filters.insert_name,
      type_insert: filters.type_insert,
      about_insert: filters.about_insert,
      radius: filters.radius,
      cutting_length: filters.cutting_length,
      processing_material: filters.processing_material,
    }));

    let filteredTools = [...list];
    for (let key in filters) {
      if (filters.hasOwnProperty(key)) {
        if (filters[key] !== "") {
          filteredTools = filterArrayByField(filteredTools, key, filters[key]);
        }
      }
    }

    SaveFilterCookies(filters);
    return filteredTools;
  }

  useEffect(() => {
    if (panel.isActivHour || panel.isActivDay) {
      let hours = panel.isActivDay ? 24 : 1;

      let copyList = dataToolsCopy.map((tool) => ({
        ...tool,
        selected: undefined,
      }));

      let counter = 0;
      for (let tool of copyList) {
        const dateObject = new Date(tool.update_date);
        const currentTime = new Date();
        const oneHourInMilliseconds = 60 * 60 * hours * 1000;
        if (currentTime - dateObject <= oneHourInMilliseconds) {
          copyList[counter]["selected"] = true;
        }
        counter++;
      }

      setDataToolsCopy(applyAllFilters(copyList));
      setPanelChanged(true);
    } else if (panelChanged) {
      if (!panel.isActivHour && !panel.isActivDay) {
        let copyList = dataToolsCopy.map((tool) => ({
          ...tool,
          selected: undefined,
        }));

        setDataToolsCopy(applyAllFilters(copyList));
      }
    } else {
      setDataToolsCopy(applyAllFilters(dataTools));
    }
  }, [dataTools, panel]);

  /**
   * Фильтрует массив объектов по значению указанного поля.
   *
   * @param {Array} array - Массив объектов для фильтрации.
   * @param {string} field - Поле объекта, по которому будет производиться фильтрация.
   * @param {string} searchString - Строка для поиска в значении указанного поля.
   * @returns {Array} - Новый массив объектов, содержащий только те объекты, у которых значение указанного поля содержит строку поиска.
   */
  function filterArrayByField(array, field, searchString) {
    return array.filter((item) =>
      String(item[field]).toLowerCase().includes(searchString.toLowerCase())
    );
  }

  UseCtrlZButton(
    setFilter,
    setPanel,
    setDataToolsCopy,
    setIsHoveredFilter,
    SaveFilterCookies,
    dataTools
  );

  // const [visibleRows, setVisibleRows] = useState([]);

  // UseVisibleRows(dataToolsCopy, setVisibleRows);
  // let visibleRows = UseVisibleRows(dataToolsCopy);

  ////////////////////////////////////////////////////////////////////////

  return (
    <section>
      <div>
        {loading && <p>Loading...</p>}

        {!loading && !noCotent && (
          <table className="table">
            <thead
              onMouseLeave={() => {
                if (
                  filter.registration_date === "" &&
                  filter.username === "" &&
                  filter.show_status === "" &&
                  filter.count === "" &&
                  filter.used_count === "" &&
                  filter.about_history === "" &&
                  filter.brand === "" &&
                  filter.insert_name === "" &&
                  filter.type_insert === "" &&
                  filter.about_insert === "" &&
                  filter.radius === "" &&
                  filter.cutting_length === "" &&
                  filter.processing_material === ""
                ) {
                  handleMouseLeave();
                }
              }}
              onMouseEnter={handleMouseEnter}
            >
              <tr
                className="sort"
                onMouseEnter={() => {
                  setIsHoveredFilter(true);
                }}
              >
                <th>
                  <p
                    onClick={() => {
                      sortDataList("registration_date");
                    }}
                  >
                    Дата получения
                  </p>
                </th>
                <th>
                  <p
                    onClick={() => {
                      sortDataList("username");
                    }}
                  >
                    Пользователь
                  </p>
                </th>
                <th>
                  <p
                    onClick={() => {
                      sortDataList("show_status");
                    }}
                  >
                    Статус
                  </p>
                </th>
                <th>
                  <p
                    onClick={() => {
                      sortDataList("count");
                    }}
                  >
                    Количество
                  </p>
                </th>
                <th>
                  <p
                    onClick={() => {
                      sortDataList("used_count");
                    }}
                  >
                    Количество Б/У
                  </p>
                </th>
                <th>
                  <p
                    onClick={() => {
                      sortDataList("about_history");
                    }}
                  >
                    Коментарий
                  </p>
                </th>

                <th>
                  <p
                    onClick={() => {
                      sortDataList("brand");
                    }}
                  >
                    Производитель
                  </p>
                </th>
                <th>
                  <p
                    onClick={() => {
                      sortDataList("insert_name");
                    }}
                  >
                    Маркировка
                  </p>
                </th>
                <th>
                  <p
                    onClick={() => {
                      sortDataList("type_insert");
                    }}
                  >
                    Тип пластины
                  </p>
                </th>

                <th>
                  <p
                    onClick={() => {
                      sortDataList("about_insert");
                    }}
                  >
                    Описание
                  </p>
                </th>
                <th>
                  <p
                    onClick={() => {
                      sortDataList("radius");
                    }}
                  >
                    Радиус
                  </p>
                </th>
                <th>
                  <p
                    onClick={() => {
                      sortDataList("cutting_length");
                    }}
                  >
                    Длина режущей кромки
                  </p>
                </th>
                <th>
                  <p
                    onClick={() => {
                      sortDataList("processing_material");
                    }}
                  >
                    Материал обработки
                  </p>
                </th>
              </tr>

              {isHoveredFilter && (
                <tr className="filter">
                  <th>
                    <input
                      type="text"
                      id="registration_date"
                      value={filter.registration_date}
                      onChange={handleFilterChange}
                    />
                  </th>
                  <th>
                    <input
                      type="text"
                      id="username"
                      value={filter.username}
                      onChange={handleFilterChange}
                    />
                  </th>
                  <th>
                    <input
                      type="text"
                      id="show_status"
                      value={filter.show_status}
                      onChange={handleFilterChange}
                    />
                  </th>
                  <th>
                    <input
                      type="text"
                      id="count"
                      value={filter.count}
                      onChange={handleFilterChange}
                    />
                  </th>
                  <th>
                    <input
                      type="text"
                      id="used_count"
                      value={filter.used_count}
                      onChange={handleFilterChange}
                    />
                  </th>
                  <th>
                    <input
                      type="text"
                      id="about_history"
                      value={filter.about_history}
                      onChange={handleFilterChange}
                    />
                  </th>
                  <th>
                    <input
                      type="text"
                      id="brand"
                      value={filter.brand}
                      onChange={handleFilterChange}
                    />
                  </th>
                  <th>
                    <input
                      type="text"
                      id="tool_name"
                      value={filter.insert_name}
                      onChange={handleFilterChange}
                    />
                  </th>
                  <th>
                    <input
                      type="text"
                      id="type_tool"
                      value={filter.type_insert}
                      onChange={handleFilterChange}
                    />
                  </th>

                  <th>
                    <input
                      type="text"
                      id="about_tool"
                      value={filter.about_insert}
                      onChange={handleFilterChange}
                    />
                  </th>
                  <th>
                    <input
                      type="text"
                      id="diameter"
                      value={filter.radius}
                      onChange={handleFilterChange}
                    />
                  </th>
                  <th>
                    <input
                      type="text"
                      id="cutting_length"
                      value={filter.cutting_length}
                      onChange={handleFilterChange}
                    />
                  </th>
                  <th>
                    <input
                      type="text"
                      id="processing_material"
                      value={filter.processing_material}
                      onChange={handleFilterChange}
                    />
                  </th>
                </tr>
              )}
            </thead>

            <tbody>
              {dataToolsCopy.map((row, index) => (
                // {visibleRows.map((row, index) => (
                <tr
                  key={row.id}
                  // key={uuidv4()}
                  className={` ${
                    row.selected !== undefined
                      ? "table_updated fade-in"
                      : "fade-in"
                  }`}
                >
                  <td>{format(row.registration_date, "dd.MM.yyyy HH:mm")}</td>
                  {/* <td>{row.email}</td> */}
                  <td>
                    {row.username}
                    <br />
                    <span className="gray_text">
                      {row.email.length > 14
                        ? `${row.email.substring(0, 14)}...`
                        : row.email}
                    </span>
                  </td>
                  <td>{row.show_status}</td>
                  <td>{row.count}</td>
                  <td>{row.used_count}</td>
                  <td>{row.about_history}</td>
                  <td>{row.brand}</td>
                  <td>{row.insert_name}</td>
                  <td>{row.type_insert}</td>
                  <td>{row.about_insert}</td>
                  <td>{row.radius}</td>
                  <td>{row.cutting_length}</td>
                  <td>{row.processing_material}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <Notification dataNotification={dataNotification}>
        {dataNotification.children}
      </Notification>
    </section>
  );
}
