import { useState, useEffect } from "react";
import Button from "../../../components/button/Button";
import Notification from "../../../components/notification/Notification";
import ToolForm from "./toolForm";
import { queryCerateTools } from "../api/createToolData";

export default function CerateTool(data) {
  const [form, setForm] = useState({
    brand: "",
    tool_name: "",
    type_tool: "",
    construction: "",
    count: "",
    used_count: "",
    need_count: "",
    about_tool: "",
    diameter: "",
    cutting_length: "",
    processing_material: "",

    hasErrorValodation: true,
    hasErrorToolname: false,
    hasErrorDiameter: false,
    hasErrorLength: false,
    hasErrorTypeTool: false,
  });

  const [options, setOptions] = useState({
    brand: [],
    tool_name: [],
    type_tool: [],
    construction: [],
    count: [],
    used_count: [],
    need_count: [],
    about_tool: [],
    diameter: [],
    cutting_length: [],
    processing_material: [],
  });

  /**
   * Обрабатывает изменения в форме и обновляет состояние формы.
   *
   * @param {Event} event - Событие, вызванное изменением в форме.
   */
  function handleForm(event) {
    let currentTool = {
      brand: form.brand,
      tool_name: form.tool_name,
      type_tool: form.type_tool,
      construction: form.construction,
      count: form.count,
      used_count: form.used_count,
      need_count: form.need_count,
      about_tool: form.about_tool,
      diameter: form.diameter,
      cutting_length: form.cutting_length,
      processing_material: form.processing_material,

      hasErrorValodation: form.hasErrorValodation,
      hasErrorToolname: form.hasErrorToolname,
      hasErrorDiameter: form.hasErrorDiameter,
      hasErrorLength: form.hasErrorLength,
      hasErrorTypeTool: form.hasErrorTypeTool,
    };

    currentTool[event.target.id] = event.target.value;

    if (event.target.id === "tool_name") {
      if (duplicationСheck("tool_name", event.target.value)) {
        data.handleNotification(
          "инструмент с такой маркировкой уже существует",
          "red"
        );
        currentTool["hasErrorToolname"] = true;
      } else {
        currentTool["hasErrorToolname"] = false;
      }
    }

    if (event.target.value.trim() === "") {
      switch (event.target.id) {
        case "tool_name":
          currentTool["hasErrorToolname"] = true;
          break;
        case "type_tool":
          currentTool["hasErrorTypeTool"] = true;
          break;
        case "diameter":
          currentTool["hasErrorDiameter"] = true;
          break;
        case "cutting_length":
          currentTool["hasErrorLength"] = true;
          break;
      }
    }

    if (event.target.value.trim() !== "") {
      switch (event.target.id) {
        case "tool_name":
          currentTool["hasErrorToolname"] = false;
          break;
        case "type_tool":
          currentTool["hasErrorTypeTool"] = false;
          break;
        case "diameter":
          currentTool["hasErrorDiameter"] = false;
          break;
        case "cutting_length":
          currentTool["hasErrorLength"] = false;
          break;
      }
    }

    if (
      !currentTool.hasErrorToolname &&
      !currentTool.hasErrorTypeTool &&
      !currentTool.hasErrorDiameter &&
      !currentTool.hasErrorLength &&
      currentTool.tool_name !== "" &&
      currentTool.type_tool !== "" &&
      currentTool.diameter !== "" &&
      currentTool.cutting_length !== ""
    ) {
      currentTool["hasErrorValodation"] = false;
    } else {
      currentTool["hasErrorValodation"] = true;
    }

    setForm((prev) => ({
      ...prev,
      brand: currentTool.brand,
      tool_name: currentTool.tool_name,
      type_tool: currentTool.type_tool,
      construction: currentTool.construction,
      count: currentTool.count,
      used_count: currentTool.used_count,
      need_count: currentTool.need_count,
      about_tool: currentTool.about_tool,
      diameter: currentTool.diameter,
      cutting_length: currentTool.cutting_length,
      processing_material: currentTool.processing_material,

      hasErrorValodation: currentTool.hasErrorValodation,
      hasErrorToolname: currentTool.hasErrorToolname,
      hasErrorDiameter: currentTool.hasErrorDiameter,
      hasErrorLength: currentTool.hasErrorLength,
      hasErrorTypeTool: currentTool.hasErrorTypeTool,
    }));
  }

  /**
   * Checks for duplication of a value in the dataTools array based on a specified key.
   *
   * @param {string} key - The key to check in each object within the dataTools array.
   * @param {string} value - The value to compare against the values in the dataTools array.
   * @returns {boolean} - Returns true if a duplicate is found, otherwise false.
   */
  function duplicationСheck(key, value) {
    let index = data.dataTools.findIndex(
      (tool) => tool[key].trim() === value.trim()
    );

    if (index !== -1) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Функция для очистки формы.
   * Устанавливает значения всех полей формы в пустые строки или начальные значения.
   * Также сбрасывает состояния ошибок валидации.
   */
  function clearForm() {
    setForm(() => ({
      brand: "",
      tool_name: "",
      type_tool: "",
      construction: "",
      count: "",
      used_count: "",
      need_count: "",
      about_tool: "",
      diameter: "",
      cutting_length: "",
      processing_material: "",

      hasErrorValodation: true,
      hasErrorToolname: false,
      hasErrorDiameter: false,
      hasErrorLength: false,
      hasErrorTypeTool: false,
    }));
  }

  /**
   * Получает уникальные значения указанного поля из отфильтрованного списка инструментов.
   *
   * @param {string} fieldName - Название поля, значения которого нужно получить.
   * @returns {Array} - Массив уникальных значений указанного поля.
   */
  function getUniqueFieldValues(fieldName) {
    let filteredTools = data.dataTools;
    filteredTools = filterArrayByField(filteredTools, "brand", form.brand);
    filteredTools = filterArrayByField(
      filteredTools,
      "tool_name",
      form.tool_name
    );
    filteredTools = filterArrayByField(
      filteredTools,
      "type_tool",
      form.type_tool
    );
    filteredTools = filterArrayByField(
      filteredTools,
      "construction",
      form.construction
    );
    filteredTools = filterArrayByField(
      filteredTools,
      "diameter",
      form.diameter
    );

    let fieldValues = [];
    for (const tool of filteredTools) {
      fieldValues.push(tool[fieldName]);
    }
    return [...new Set(fieldValues)];
  }

  /**
   * Фильтрует массив объектов по значению указанного поля.
   *
   * @param {Array} array - Массив объектов для фильтрации.
   * @param {string} field - Поле объекта, по которому будет производиться фильтрация.
   * @param {string} searchString - Строка для поиска в значении указанного поля.
   * @returns {Array} - Новый массив объектов, содержащий только те объекты, у которых значение указанного поля содержит строку поиска.
   */
  function filterArrayByField(array, field, searchString) {
    return array.filter((item) =>
      String(item[field]).toLowerCase().includes(searchString.toLowerCase())
    );
  }

  useEffect(() => {
    setOptions((prev) => ({
      ...prev,
      brand: getUniqueFieldValues("brand"),
      tool_name: getUniqueFieldValues("tool_name"),
      type_tool: getUniqueFieldValues("type_tool"),
      construction: getUniqueFieldValues("construction"),
      diameter: getUniqueFieldValues("diameter"),
      processing_material: getUniqueFieldValues("processing_material"),
    }));
  }, [form, data.modalAdd]);

  if (!data.modalAdd) {
    return <></>;
  }

  ////////////////////////////////////////////////////////////////////////

  return (
    <section>
      <h3>Добавление инструмента</h3>
      <ToolForm
        formState={form}
        optionsState={options}
        handle={handleForm}
      ></ToolForm>

      <div className="modal_buttom">
        <Button
          onClick={() => {
            data.setModalAdd(false);
          }}
        >
          отмена
        </Button>
        <Button
          onClick={() => {
            clearForm();
          }}
        >
          очистить
        </Button>
        <Button
          disabled={form.hasErrorValodation}
          onClick={() => {
            (async () => {
              let [err, responseData] = await queryCerateTools(form);

              if (err !== null) {
                data.handleNotification(err, "red");
              } else {
                data.handleNotification("Инструмент добавлен", "green");
                data.setModalAdd(false);

                clearForm();

                data.setDataTools([...data.dataTools, responseData]);
                data.setDataToolsCopy([...data.dataToolsCopy, responseData]);

                if (data.dataTools.length === 0) {
                  data.setNoCotent(false);
                }
              }
            })();
          }}
        >
          добавить
        </Button>
      </div>

      <Notification dataNotification={data.dataNotification}>
        {data.dataNotification.children}
      </Notification>
    </section>
  );
}
