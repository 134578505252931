import GetCookie from "../../../utils/coockies";
import { BaseAPIUrl } from "../../../config/config";

export async function queryFetchTHistorys() {
  const token = GetCookie("token");

  let dataTools = null;
  let err = null;

  try {
    const url = `${BaseAPIUrl}api/thistorys/`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Token: token,
      },
    });

    // const data = response.ok ? await response.json() : null;
    // console.log('---data---', data)

    switch (response.status) {
      case 200:
        const data = response.ok ? await response.json() : null;
        dataTools = data;
        break;
      case 204:
        dataTools = [];
        break;
      case 401:
        err = "пользователь неавторизирован";
        break;
      case 400:
        err = "неправильный запрос";
        break;
      case 500:
        err = "ошибка сервера";
        break;
      default:
        err = `код ответа: ${response.status}`;
    }
  } catch (error) {
    err = `ошибка подключения к серверу ${error}`;
  }

  return [err, dataTools];
}
