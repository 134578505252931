import GetCookie from "../../../utils/coockies";

export function SaveFilterCookies(filters) {
  const jsonString = JSON.stringify(filters);
  document.cookie = `thistory_filters=${jsonString}; path=/;`;
}

export function GetFilterCookies() {
  const jsonFilters = GetCookie("thistory_filters");
  let filters;
  const defaultFilters = {
    registration_date: "",
    username: "",
    show_status: "",
    count: "",
    used_count: "",
    about_history: "",
    brand: "",
    tool_name: "",
    type_tool: "",
    construction: "",
    about_tool: "",
    diameter: "",
    cutting_length: "",
    processing_material: "",
  };

  if (jsonFilters !== null && jsonFilters !== "") {
    filters = JSON.parse(jsonFilters);

    if (filters.brand === undefined) {
      filters = defaultFilters;
    }
  } else {
    filters = defaultFilters;
  }

  return filters;
}

export function SaveScrollCookies(position) {
  document.cookie = `thistory_scroll=${position}; path=/;`;
}

export function GetScrollCookies() {
  const jsonScroll = GetCookie("tthistory_scroll");
  const scroll = JSON.parse(jsonScroll);
  return scroll;
}
