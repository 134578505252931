import { Helmet, HelmetProvider } from "react-helmet-async";
import { FetchTHistorys } from "../modules/thistory/index";

export default function THistoryPage() {
  return (
    <section>
      <HelmetProvider>
        <Helmet>
          <title>история получения инструмента</title>
        </Helmet>
      </HelmetProvider>

      <FetchTHistorys />
    </section>
  );
}
