import { useState, useEffect } from "react";
import ToolForm from "./toolForm";

export default function UpdateTool(data) {
  function handleUpdateChange(event) {
    let currentTool = {
      brand: data.row.brand,
      tool_name: data.row.tool_name,
      type_tool: data.row.type_tool,
      construction: data.row.construction,
      count: data.row.count,
      used_count: data.row.used_count,
      need_count: data.row.need_count,
      about_tool: data.row.about_tool,
      diameter: data.row.diameter,
      cutting_length: data.row.cutting_length,
      processing_material: data.row.processing_material,
    };

    currentTool[event.target.id] = event.target.value;

    data.setCurrentRow((prev) => ({
      ...prev,
      brand: currentTool.brand,
      tool_name: currentTool.tool_name,
      type_tool: currentTool.type_tool,
      construction: currentTool.construction,
      count: currentTool.count,
      used_count: currentTool.used_count,
      need_count: currentTool.need_count,
      about_tool: currentTool.about_tool,
      diameter: currentTool.diameter,
      cutting_length: currentTool.cutting_length,
      processing_material: currentTool.processing_material,
    }));
  }

  const [options, setOptions] = useState({
    brand: [],
    tool_name: [],
    type_tool: [],
    construction: [],
    count: [],
    used_count: [],
    need_count: [],
    about_tool: [],
    diameter: [],
    cutting_length: [],
    processing_material: [],
  });

  function getUniqueFieldValues(fieldName) {
    let filteredTools = data.dataTools;

    let fieldValues = [];
    for (const tool of filteredTools) {
      fieldValues.push(tool[fieldName]);
    }
    return [...new Set(fieldValues)];
  }

  useEffect(() => {
    setOptions((prev) => ({
      ...prev,
      brand: getUniqueFieldValues("brand"),
      tool_name: getUniqueFieldValues("tool_name"),
      type_tool: getUniqueFieldValues("type_tool"),
      construction: getUniqueFieldValues("construction"),
      diameter: getUniqueFieldValues("diameter"),
      processing_material: getUniqueFieldValues("processing_material"),
    }));
  }, [data.row, data.modal]);

  if (!data.modal) {
    return <></>;
  }

  ////////////////////////////////////////////////////////////////////////

  return (
    <section>
      <h3>Редактирование инструмента</h3>
      <ToolForm
        formState={data.row}
        optionsState={options}
        handle={handleUpdateChange}
      ></ToolForm>
    </section>
  );
}
