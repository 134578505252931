import { useEffect, useState, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import Modal from "../../components/modal/Modal";
import Button from "../../components/button/Button";
import Notification from "../../components/notification/Notification";
import CerateTool from "./components/createTool";
import UpdateTool from "./components/updateTool";
import { toolValidation } from "./helpers/validation";
import { SaveFilterCookies, GetFilterCookies } from "./components/cookies";
import { queryUpdateTools } from "./api/updateToolData";
import { queryDeleteTools } from "./api/deleteToolData";
import { queryFetchTools } from "./api/fetchToolData";
import { UseEscButton } from "./hooks/useEscButton";
import { UseCtrlZButton } from "./hooks/useCtrlZButton";
// import { getToken } from "../../pages/Signin";
import GetCookie from "../../utils/coockies";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
// import { UseVisibleRows } from "./hooks/useVisibleRows";

export function FetchTools() {
  const [modal, setModal] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [currentRow, setCurrentRow] = useState({
    brand: "",
    tool_name: "",
    type_tool: "",
    construction: "",
    count: "",
    used_count: "",
    need_count: "",
    about_tool: "",
    diameter: "",
    cutting_length: "",
    processing_material: "",

    hasErrorValodation: true,
    hasErrorToolname: false,
    hasErrorDiameter: false,
    hasErrorLength: false,
    hasErrorTypeTool: false,
  });
  const [loading, setLoading] = useState(false);
  const [noCotent, setNoCotent] = useState(false);
  const [errorValidation, setErrorValidation] = useState(false);
  const [dataToolsCopy, setDataToolsCopy] = useState([]);
  const [dataTools, setDataTools] = useState([]);
  const [sort, setSort] = useState({
    sortColumnFlag: "brand",
    sortDirFlag: true,
  });

  const cooliesFilters = GetFilterCookies();
  const [isHoveredFilter, setIsHoveredFilter] = useState(
    cooliesFilters.brand !== "" ||
      cooliesFilters.tool_name !== "" ||
      cooliesFilters.type_tool !== "" ||
      cooliesFilters.construction !== "" ||
      cooliesFilters.count !== "" ||
      cooliesFilters.used_count !== "" ||
      cooliesFilters.need_count !== "" ||
      cooliesFilters.about_tool !== "" ||
      cooliesFilters.diameter !== "" ||
      cooliesFilters.cutting_length !== "" ||
      cooliesFilters.processing_material !== ""
  );
  const [filter, setFilter] = useState({
    brand: cooliesFilters.brand,
    tool_name: cooliesFilters.tool_name,
    type_tool: cooliesFilters.type_tool,
    construction: cooliesFilters.construction,
    count: cooliesFilters.count,
    used_count: cooliesFilters.used_count,
    need_count: cooliesFilters.need_count,
    about_tool: cooliesFilters.about_tool,
    diameter: cooliesFilters.diameter,
    cutting_length: cooliesFilters.cutting_length,
    processing_material: cooliesFilters.processing_material,
  });

  const [panel, setPanel] = useState({
    isActivHour: false,
    isActivDay: false,
  });
  const [panelChanged, setPanelChanged] = useState(false);

  const timerRef = useRef(null);

  const handleMouseLeave = () => {
    timerRef.current = setTimeout(() => {
      setIsHoveredFilter(false);
    }, 2000);
  };

  const handleMouseEnter = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  };

  const [dataNotification, setDataNotification] = useState({
    children: null,
    id: null,
    color: "red",
  });

  /**
   * Обновляет данные уведомления новыми значениями.
   *
   * @param {React.ReactNode} children - Контент, который будет отображаться в уведомлении.
   * @param {string} color - Цвет уведомления.
   */
  function handleNotification(children, color) {
    setDataNotification({
      children: children,
      id: uuidv4(),
      color: color,
    });
  }

  /**
   * Обрабатывает изменение фильтра и обновляет отфильтрованный список инструментов.
   *
   * @param {Object} event - Событие, вызванное изменением фильтра.
   */
  function handleFilterChange(event) {
    let filteredList = filterChange(
      event.target.id,
      event.target.value,
      dataTools
    );
    setDataToolsCopy(filteredList);
  }

  /**
   * Функция для сортировки списка данных по указанному столбцу.
   *
   * Функция выполняет следующие действия:
   * 1. Определяет направление сортировки (по возрастанию или убыванию) в зависимости от текущего состояния сортировки.
   * 2. Обновляет состояние сортировки с новыми значениями столбца и направления.
   * 3. Сортирует копию данных (`dataToolsCopy`) по указанному столбцу и направлению.
   *
   * @param {string} sortColumnFlag - Название столбца, по которому необходимо выполнить сортировку.
   * @returns {void}
   */
  function sortDataList(sortColumnFlag) {
    let sortDirFlag = true;
    if (sort.sortColumnFlag === sortColumnFlag) {
      sortDirFlag = true;
      if (sort.sortDirFlag === true) {
        sortDirFlag = false;
      }
    }

    setSort(() => ({
      sortDirFlag: sortDirFlag,
      sortColumnFlag: sortColumnFlag,
    }));

    setDataToolsCopy(
      dataToolsCopy.sort(function (a, b) {
        let sortValue = a[sortColumnFlag] < b[sortColumnFlag];
        if (sortDirFlag === false)
          sortValue = a[sortColumnFlag] > b[sortColumnFlag];
        return sortValue ? -1 : 1;
      })
    );
  }

  useEffect(() => {
    setLoading(true);
    (async () => {
      let [err, data] = await queryFetchTools();

      if (err !== null) {
        handleNotification(err, "red");
        setLoading(false);
        setNoCotent(true);
      } else if (data.length === 0) {
        setNoCotent(true);
        setLoading(false);
        handleNotification(
          "У вас еще нет инструмента, добавльте его",
          "yellow"
        );
      } else {
        setDataTools(
          data.sort(function (a, b) {
            let sortValue = a[sort.sortColumnFlag] < b[sort.sortColumnFlag];
            if (sort.sortDirFlag === false)
              sortValue = a[sort.sortColumnFlag] > b[sort.sortColumnFlag];
            return sortValue ? -1 : 1;
          })
        );
        setLoading(false);
      }
    })();
  }, []);

  /**
   * Применяет все фильтры к списку инструментов и возвращает отфильтрованный список.
   *
   * @param {Array} list - Список инструментов для фильтрации.
   * @returns {Array} - Отфильтрованный список инструментов.
   */
  function applyAllFilters(list) {
    let filteredList = [...list];
    for (let key in filter) {
      if (filter[key] !== "") {
        filteredList = filterChange(key, filter[key], filteredList);
      }
    }
    return filteredList;
  }

  /**
   * Обновляет состояние фильтра и возвращает отфильтрованный список инструментов.
   *
   * @param {string} key - Ключ фильтра, который нужно обновить.
   * @param {string} value - Новое значение для указанного ключа фильтра.
   * @param {Array} list - Список инструментов для фильтрации.
   * @returns {Array} - Отфильтрованный список инструментов.
   */
  function filterChange(key, value, list) {
    let filters = {
      brand: filter.brand,
      tool_name: filter.tool_name,
      type_tool: filter.type_tool,
      construction: filter.construction,
      count: filter.count,
      used_count: filter.used_count,
      need_count: filter.need_count,
      about_tool: filter.about_tool,
      diameter: filter.diameter,
      cutting_length: filter.cutting_length,
      processing_material: filter.processing_material,
    };

    filters[key] = value;

    setFilter((prev) => ({
      ...prev,
      brand: filters.brand,
      tool_name: filters.tool_name,
      type_tool: filters.type_tool,
      construction: filters.construction,
      count: filters.count,
      used_count: filters.used_count,
      need_count: filters.need_count,
      about_tool: filters.about_tool,
      diameter: filters.diameter,
      cutting_length: filters.cutting_length,
      processing_material: filters.processing_material,
    }));

    let filteredTools = [...list];
    for (let key in filters) {
      if (filters.hasOwnProperty(key)) {
        if (filters[key] !== "") {
          filteredTools = filterArrayByField(filteredTools, key, filters[key]);
        }
      }
    }

    SaveFilterCookies(filters);
    return filteredTools;
  }

  useEffect(() => {
    if (panel.isActivHour || panel.isActivDay) {
      let hours = panel.isActivDay ? 24 : 1;

      let copyList = dataToolsCopy.map((tool) => ({
        ...tool,
        selected: undefined,
      }));

      let counter = 0;
      for (let tool of copyList) {
        const dateObject = new Date(tool.update_date);
        const currentTime = new Date();
        const oneHourInMilliseconds = 60 * 60 * hours * 1000;
        if (currentTime - dateObject <= oneHourInMilliseconds) {
          copyList[counter]["selected"] = true;
        }
        counter++;
      }

      setDataToolsCopy(applyAllFilters(copyList));
      setPanelChanged(true);
    } else if (panelChanged) {
      if (!panel.isActivHour && !panel.isActivDay) {
        let copyList = dataToolsCopy.map((tool) => ({
          ...tool,
          selected: undefined,
        }));

        setDataToolsCopy(applyAllFilters(copyList));
      }
    } else {
      setDataToolsCopy(applyAllFilters(dataTools));
    }
  }, [dataTools, panel]);

  /**
   * Фильтрует массив объектов по значению указанного поля.
   *
   * @param {Array} array - Массив объектов для фильтрации.
   * @param {string} field - Поле объекта, по которому будет производиться фильтрация.
   * @param {string} searchString - Строка для поиска в значении указанного поля.
   * @returns {Array} - Новый массив объектов, содержащий только те объекты, у которых значение указанного поля содержит строку поиска.
   */
  function filterArrayByField(array, field, searchString) {
    return array.filter((item) =>
      String(item[field]).toLowerCase().includes(searchString.toLowerCase())
    );
  }

  UseCtrlZButton(
    setFilter,
    setPanel,
    setDataToolsCopy,
    setIsHoveredFilter,
    SaveFilterCookies,
    dataTools
  );

  UseEscButton(setModal, setModalAdd);

  /**
   * Удаляет элемент из списка инструментов.
   * @param {Object} row - Объект строки, который нужно удалить.
   */
  function deleteInListTools(row) {
    let index = dataTools.findIndex((tool) => tool.id === row.id);
    let indexCipy = dataTools.findIndex((tool) => tool.id === row.id);
    let updatedDataTools = [...dataTools];
    updatedDataTools.splice(index, 1);

    let updatedDataToolsCopy = [...dataToolsCopy];
    updatedDataToolsCopy.splice(indexCipy, 1);
    setDataTools(updatedDataTools);
    setDataToolsCopy(updatedDataToolsCopy);
  }

  /**
   * Обновляет элемент в списке инструментов.
   * @param {Object} row - Объект строки, который нужно обновить.
   */
  function updateInListTools(row) {
    let index = dataTools.findIndex((tool) => tool.id === row.id);
    let updatedDataTools = [...dataTools];
    updatedDataTools[index] = row;

    let indexCopy = dataToolsCopy.findIndex((tool) => tool.id === row.id);
    let updatedDataToolsCopy = [...dataToolsCopy];
    updatedDataToolsCopy[indexCopy] = row;

    setDataTools(updatedDataTools);
    setDataToolsCopy(updatedDataToolsCopy);
  }

  useEffect(() => {
    if (currentRow.brand !== null) {
      let originalTool =
        dataTools[dataTools.findIndex((tool) => tool.id === currentRow.id)];

      if (toolValidation(currentRow, originalTool)) {
        setErrorValidation(false);
      } else {
        setErrorValidation(true);
      }
    }
  }, [currentRow]);

  if (GetCookie("token") === null) {
    return <Navigate to="/singin/" />;
  }

  // let visibleRows = UseVisibleRows(dataToolsCopy)

  ////////////////////////////////////////////////////////////////////////

  return (
    <section>
      <div className="panel">
        <ul>
          <li>
            <Button
              onClick={() => {
                setModalAdd(true);
              }}
            >
              добавить инструмент
            </Button>
          </li>

          {!loading && !noCotent && (
            <>
              <li>
                <Button
                  onClick={() => {
                    if (panel.isActivDay) {
                      setPanel((prev) => ({
                        ...prev,
                        isActivDay: false,
                        isActivHour: false,
                      }));
                    } else {
                      setPanel((prev) => ({
                        ...prev,
                        isActivDay: true,
                        isActivHour: false,
                      }));
                    }
                  }}
                  isActive={panel.isActivDay}
                >
                  обновленния за сутки
                </Button>
              </li>
              <li>
                <Button
                  onClick={() => {
                    if (panel.isActivHour) {
                      setPanel((prev) => ({
                        ...prev,
                        isActivDay: false,
                        isActivHour: false,
                      }));
                    } else {
                      setPanel((prev) => ({
                        ...prev,
                        isActivDay: false,
                        isActivHour: true,
                      }));
                    }
                  }}
                  isActive={panel.isActivHour}
                >
                  обновленния за час
                </Button>
              </li>
              <li>
                <p>Alt+Z - очистка фильтров</p>
              </li>
            </>
          )}
        </ul>
      </div>
      <Modal open={modalAdd}>
        <CerateTool
          dataTools={dataTools}
          setDataTools={setDataTools}
          dataToolsCopy={dataToolsCopy}
          setDataToolsCopy={setDataToolsCopy}
          modalAdd={modalAdd}
          setModalAdd={setModalAdd}
          dataNotification={dataNotification}
          handleNotification={handleNotification}
          setNoCotent={setNoCotent}
        ></CerateTool>
      </Modal>
      <Modal open={modal}>
        <UpdateTool
          row={currentRow}
          setCurrentRow={setCurrentRow}
          modal={modal}
          dataTools={dataTools}
        ></UpdateTool>
        <div className="modal_buttom">
          <Button onClick={() => setModal(false)}>отмена</Button>
          <Button
            onClick={() => {
              (async () => {
                let err = await queryDeleteTools(currentRow.id);

                if (err !== null) {
                  handleNotification(err, "red");
                } else {
                  handleNotification("Инструмент удален", "yellow");
                  setModal(false);

                  deleteInListTools(currentRow);
                }
              })();
            }}
          >
            удалить
          </Button>
          <Button
            disabled={errorValidation}
            onClick={() => {
              (async () => {
                let [err, responseData] = await queryUpdateTools(currentRow);

                if (err !== null) {
                  handleNotification(err, "red");
                } else {
                  handleNotification("Инструмент обновлен", "green");
                  setModal(false);
                  updateInListTools(responseData);
                }
              })();
            }}
          >
            обновить
          </Button>
        </div>
      </Modal>

      <div>
        {loading && <p>Loading...</p>}

        {!loading && !noCotent && (
          <table className="table">
            <thead
              onMouseLeave={() => {
                if (
                  filter.brand === "" &&
                  filter.tool_name === "" &&
                  filter.type_tool === "" &&
                  filter.construction === "" &&
                  filter.count === "" &&
                  filter.used_count === "" &&
                  filter.need_count === "" &&
                  filter.about_tool === "" &&
                  filter.diameter === "" &&
                  filter.cutting_length === "" &&
                  filter.processing_material === ""
                ) {
                  handleMouseLeave();
                }
              }}
              onMouseEnter={handleMouseEnter}
            >
              <tr
                className="sort"
                onMouseEnter={() => {
                  setIsHoveredFilter(true);
                }}
              >
                <th>
                  <p
                    onClick={() => {
                      sortDataList("brand");
                    }}
                  >
                    Производитель
                  </p>
                </th>
                <th>
                  <p
                    onClick={() => {
                      sortDataList("tool_name");
                    }}
                  >
                    Маркировка
                  </p>
                </th>
                <th>
                  <p
                    onClick={() => {
                      sortDataList("type_tool");
                    }}
                  >
                    Тип инструмента
                  </p>
                </th>
                <th>
                  <p
                    onClick={() => {
                      sortDataList("construction");
                    }}
                  >
                    Конструкция
                  </p>
                </th>
                <th>
                  <p
                    onClick={() => {
                      sortDataList("count");
                    }}
                  >
                    Количество
                  </p>
                </th>
                <th>
                  <p
                    onClick={() => {
                      sortDataList("used_count");
                    }}
                  >
                    Количество Б/У
                  </p>
                </th>
                <th>
                  <p
                    onClick={() => {
                      sortDataList("need_count");
                    }}
                  >
                    Необходимое количество
                  </p>
                </th>
                <th>
                  <p
                    onClick={() => {
                      sortDataList("about_tool");
                    }}
                  >
                    Описание
                  </p>
                </th>
                <th>
                  <p
                    onClick={() => {
                      sortDataList("diameter");
                    }}
                  >
                    Диаметр
                  </p>
                </th>
                <th>
                  <p
                    onClick={() => {
                      sortDataList("cutting_length");
                    }}
                  >
                    Длина режущей кромки
                  </p>
                </th>
                <th>
                  <p
                    onClick={() => {
                      sortDataList("processing_material");
                    }}
                  >
                    Материал обработки
                  </p>
                </th>
              </tr>

              {isHoveredFilter && (
                <tr className="filter">
                  <th>
                    <input
                      type="text"
                      id="brand"
                      value={filter.brand}
                      onChange={handleFilterChange}
                    />
                  </th>
                  <th>
                    <input
                      type="text"
                      id="tool_name"
                      value={filter.tool_name}
                      onChange={handleFilterChange}
                    />
                  </th>
                  <th>
                    <input
                      type="text"
                      id="type_tool"
                      value={filter.type_tool}
                      onChange={handleFilterChange}
                    />
                  </th>
                  <th>
                    <input
                      type="text"
                      id="construction"
                      value={filter.construction}
                      onChange={handleFilterChange}
                    />
                  </th>
                  <th>
                    <input
                      type="text"
                      id="count"
                      value={filter.count}
                      onChange={handleFilterChange}
                    />
                  </th>
                  <th>
                    <input
                      type="text"
                      id="used_count"
                      value={filter.used_count}
                      onChange={handleFilterChange}
                    />
                  </th>
                  <th>
                    <input
                      type="text"
                      id="need_count"
                      value={filter.need_count}
                      onChange={handleFilterChange}
                    />
                  </th>
                  <th>
                    <input
                      type="text"
                      id="about_tool"
                      value={filter.about_tool}
                      onChange={handleFilterChange}
                    />
                  </th>
                  <th>
                    <input
                      type="text"
                      id="diameter"
                      value={filter.diameter}
                      onChange={handleFilterChange}
                    />
                  </th>
                  <th>
                    <input
                      type="text"
                      id="cutting_length"
                      value={filter.cutting_length}
                      onChange={handleFilterChange}
                    />
                  </th>
                  <th>
                    <input
                      type="text"
                      id="processing_material"
                      value={filter.processing_material}
                      onChange={handleFilterChange}
                    />
                  </th>
                </tr>
              )}
            </thead>
            <tbody>
              {dataToolsCopy.map((row) => (
                <tr
                  key={row.id}
                  className={` ${
                    row.selected !== undefined ? "table_updated" : ""
                  }`}
                  onDoubleClick={() => {
                    setCurrentRow(row);

                    // openModal();
                    setModal(true);
                  }}
                >
                  <td>{row.brand}</td>
                  <td>{row.tool_name}</td>
                  <td>{row.type_tool}</td>
                  <td>{row.construction}</td>
                  <td>{row.count}</td>
                  <td>{row.used_count}</td>
                  <td>{row.need_count}</td>
                  <td>{row.about_tool}</td>
                  <td>{row.diameter}</td>
                  <td>{row.cutting_length}</td>
                  <td>{row.processing_material}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <Notification dataNotification={dataNotification}>
        {dataNotification.children}
      </Notification>
    </section>
  );
}
