import { Helmet, HelmetProvider } from "react-helmet-async";
import Button from "../components/button/Button";
import Notification from "../components/notification/Notification";
import GetCookie from "../utils/coockies";
import { v4 as uuidv4 } from "uuid";
import { Navigate } from "react-router-dom";
import { BaseAPIUrl } from "../config/config";
import { useState } from "react";

export default function SinginPage() {
  const [dataNotification, setDataNotification] = useState({
    children: null,
    id: null,
    color: "red",
  });

  /**
   * Обновляет данные уведомления новыми значениями.
   *
   * @param {React.ReactNode} children - Контент, который будет отображаться в уведомлении.
   * @param {string} color - Цвет уведомления.
   */
  function handleNotification(children, color) {
    setDataNotification({
      children: children,
      id: uuidv4(),
      color: color,
    });
  }

  const [form, setForm] = useState({
    email: "",
    password: "",
    newPassword: "",
    verificationCode: "",
    updatePassword: false,
    hasError: true,
    hasErrorLogin: false,
    hasErrorPassword: false,
    hasErrorNewPassword: false,
    hasErrorVerificationCode: false,
  });

  function handleEmailChange(event) {
    setForm((prev) => ({
      ...prev,
      email: event.target.value,
      hasErrorLogin: event.target.value.trim().length <= 4,
    }));

    if (
      !form.hasErrorLogin &&
      !form.hasErrorPassword &&
      event.target.value.trim().length > 4 &&
      form.password.length > 4
    ) {
      setForm((prev) => ({
        ...prev,
        hasError: false,
      }));
    } else {
      setForm((prev) => ({
        ...prev,
        hasError: true,
      }));
    }
  }

  function handlePasswordChange(event) {
    setForm((prev) => ({
      ...prev,
      password: event.target.value,
      hasErrorPassword: event.target.value.trim().length <= 4,
    }));

    if (
      !form.hasErrorLogin &&
      !form.hasErrorPassword &&
      event.target.value.trim().length > 4 &&
      form.email.length > 4
    ) {
      setForm((prev) => ({
        ...prev,
        hasError: false,
      }));
    } else {
      setForm((prev) => ({
        ...prev,
        hasError: true,
      }));
    }
  }

  function handleSetNewPassword(event) {
    setForm((prev) => ({
      ...prev,
      updatePassword: true,
      hasError: true,
      hasErrorLogin: false,
      hasErrorPassword: false,
    }));

    // queryFetchVerificationCode(form.email).then(([err, verificationCode]) => {
    queryFetchVerificationCode(form.email).then(([err]) => {
      if (err) {
        handleNotification(err, "red");
      } else {
        handleNotification(
          `На вашу почту ${form.email} выслан код подтверждения`,
          "yellow"
        );
      }
    });
  }

  function handleNewPassword(event) {
    setForm((prev) => ({
      ...prev,
      newPassword: event.target.value.trim(),
      hasErrorNewPassword: event.target.value.trim().length <= 4,
    }));
    if (event.target.value.trim().length > 4) {
      setForm((prev) => ({
        ...prev,
        hasError: false,
      }));
    } else {
      setForm((prev) => ({
        ...prev,
        hasError: true,
      }));
    }
  }

  function handleVerificationCode(event) {
    setForm((prev) => ({
      ...prev,
      verificationCode: event.target.value.trim(),
      hasErrorVerificationCode: event.target.value.trim().length !== 4,
    }));

    if (event.target.value.trim().length === 4) {
      setForm((prev) => ({
        ...prev,
        hasError: false,
      }));
    } else {
      setForm((prev) => ({
        ...prev,
        hasError: true,
      }));
    }
  }

  if (GetCookie("token") !== null) {
    return <Navigate to="/tools/" />;
  }

  return (
    <section>
      <HelmetProvider>
        <Helmet>
          <title>вход в аккаунт</title>
        </Helmet>
      </HelmetProvider>

      <div className="login_blocform">
        <div className="login_form">
          <h2>Вход в аккаунт</h2>
          <form
            onSubmit={(e) => {
              e.preventDefault(); // Предотвращение перезагрузки страницы

              if (!form.updatePassword) {
                queryFetchToken(form.email, form.password).then(
                  ([err, token]) => {
                    if (err) {
                      handleNotification(err, "red");
                    } else {
                      handleNotification("Выполнен вход", "green");
                      // document.cookie = `token=${token}; path=/;`;
                      const date = new Date();
                      date.setMonth(date.getMonth() + 1); // Устанавливаем дату истечения через месяц
                      document.cookie = `token=${token}; path=/; expires=${date.toUTCString()};`;
                      window.location.reload();
                    }
                  }
                );
              } else {
                queryUpdatePassword(
                  form.email,
                  form.newPassword,
                  form.verificationCode
                ).then((err) => {
                  if (err) {
                    handleNotification(err, "red");
                  } else {
                    handleNotification("Пароль обновлен", "green");

                    setForm((prev) => ({
                      ...prev,
                      password: form.newPassword,
                      verificationCode: "",
                      updatePassword: false,
                      // hasError: true,
                      // hasErrorLogin: false,
                      hasErrorPassword: false,
                      // updatePassword: false,
                      hasError: true,
                      hasErrorLogin: false,
                      // hasErrorPassword: false,
                      hasErrorNewPassword: false,
                      hasErrorVerificationCode: false,
                    }));
                  }
                });
              }
            }}
          >
            <ul>
              <li>
                <label htmlFor="email">Логин</label>
                <input
                  type="text"
                  id="email"
                  className={`control ${form.hasErrorLogin ? "error" : ""}`}
                  value={form.email}
                  onChange={handleEmailChange}
                  disabled={form.updatePassword}
                />
              </li>
              {!form.updatePassword && (
                <li>
                  <label htmlFor="password">Пароль</label>
                  <input
                    type="password"
                    id="password"
                    className={`control ${
                      form.hasErrorPassword ? "error" : ""
                    }`}
                    // // style={{ border: '1px solid red'}}
                    // style={{
                    //   border: form.hasErrorPassword ? "1px solid red" : "none",
                    // }}
                    value={form.password}
                    onChange={handlePasswordChange}
                  />
                </li>
              )}
              {form.updatePassword && (
                <li>
                  <label htmlFor="new_password">Новый пароль</label>
                  <input
                    type="password"
                    id="new_password"
                    className={`control ${
                      form.hasErrorNewPassword ? "error" : ""
                    }`}
                    value={form.newPassword}
                    onChange={handleNewPassword}
                  />
                </li>
              )}
              {form.updatePassword && (
                <li>
                  <label htmlFor="verification_code">Код подтверждения</label>
                  <input
                    type="text"
                    id="verification_code"
                    className={`control ${
                      form.hasErrorVerificationCode ? "error" : ""
                    }`}
                    value={form.verificationCode}
                    onChange={handleVerificationCode}
                  />
                </li>
              )}
              {!form.updatePassword && (
                <li>
                  <p
                    className="forgot_password_button"
                    style={{ textAlign: "right", margin: "0px 0px 0px 0px" }}
                    onClick={handleSetNewPassword}
                  >
                    забыли пароль
                  </p>
                </li>
              )}
            </ul>

            <Button disabled={form.hasError} isActive={!form.hasError}>
              Отправить
            </Button>
          </form>
        </div>
      </div>

      <Notification dataNotification={dataNotification}>
        {dataNotification.children}
      </Notification>
    </section>
  );
}

////////////////////////////////////////////////

export async function queryFetchToken(email, password) {
  let token = null;
  let err = null;

  try {
    const response = await fetch(`${BaseAPIUrl}api/signin/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    });

    
    switch (response.status) {
      case 200:
        const data = response.ok ? response.headers.get("Token") : null;
        token = data;
        break;
      case 401:
        err = "неверный логин или пароль";
        break;
      case 400:
        err = "неправильный запрос";
        break;
      case 500:
        err = "ошибка сервера";
        break;
      default:
        err = `код ответа: ${response.status}`;
    }
  } catch (error) {
    err = `ошибка подключения к серверу ${error}`;
  }

  return [err, token];
}

////////////////////////////////////////////////

export async function queryFetchVerificationCode(email) {
  // let verificationCode = null;
  let err = null;

  try {
    const url = `${BaseAPIUrl}api/update_password/`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // Random: true,
        Email: email,
      },
    });

    // const data = response.ok ? await response.json() : null;

    switch (response.status) {
      case 200:
        // verificationCode = data.random_number;
        break;
      case 401:
        err = "пользователь неавторизирован";
        break;
      case 400:
        err = "неправильный запрос";
        break;
      case 500:
        err = "ошибка сервера";
        break;
      default:
        err = `код ответа: ${response.status}`;
    }
  } catch (error) {
    err = `ошибка подключения к серверу ${error}`;
    console.log(error);
  }

  // return [err, verificationCode];
  return [err];
}

////////////////////////////////////////////////

export async function queryUpdatePassword(email, password, verificationCode) {
  // let userData = null;
  let err = null;

  try {
    const params = new URLSearchParams({
      email: email.trim(),
      password: password.trim(),
      random_number: verificationCode.trim(),
    });

    const url = `${BaseAPIUrl}api/update_password/?${params.toString()}`;
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
    });

    // const data = response.ok ? await response.json() : null;

    switch (response.status) {
      case 200:
        // userData = data;
        break;
      case 401:
        err = "неверный код подтверждения";
        break;
      case 400:
        err = "неправильный запрос";
        break;
      case 500:
        err = "ошибка сервера";
        break;
      default:
        err = `код ответа: ${response.status}`;
    }
  } catch (error) {
    err = `ошибка подключения к серверу ${error}`;
  }

  return err;
}
