export function toolValidation(insert, originalInsert) {
  if (
    insert.insert_name.trim() === "" ||
    insert.radius.toString().trim() === "" ||
    insert.type_insert.trim() === ""
  ) {
    return false;
  }

  if (originalInsert !== null) {
    if (
      insert.brand.trim() === originalInsert.brand.trim() &&
      insert.insert_name.trim() === originalInsert.insert_name.trim() &&
      insert.type_insert.trim() === originalInsert.type_insert.trim() &&
      insert.count.toString().trim() ===
        originalInsert.count.toString().trim() &&
      insert.used_count.toString().trim() ===
        originalInsert.used_count.toString().trim() &&
      insert.need_count.toString().trim() ===
        originalInsert.need_count.toString().trim() &&
      insert.about_insert.trim() === originalInsert.about_insert.trim() &&
      insert.radius.toString().trim() ===
        originalInsert.radius.toString().trim() &&
      insert.cutting_length.toString().trim() ===
        originalInsert.cutting_length.toString().trim() &&
      insert.processing_material.trim() ===
        originalInsert.processing_material.trim()
    ) {
      return false;
    }
  }

  return true;
}

// ///////////////////////////

// /**
//  * Сравнивает два объекта на глубокое равенство.
//  *
//  * @param {Object} obj1 - Первый объект для сравнения.
//  * @param {Object} obj2 - Второй объект для сравнения.
//  * @returns {boolean} - Возвращает true, если объекты равны, иначе false.
//  */
// function deepEqual(obj1, obj2) {
//   if (obj1 === obj2) {
//     return true;
//   }

//   if (
//     typeof obj1 !== "object" ||
//     obj1 === null ||
//     typeof obj2 !== "object" ||
//     obj2 === null
//   ) {
//     return false;
//   }

//   let keys1 = Object.keys(obj1);
//   let keys2 = Object.keys(obj2);

//   if (keys1.length !== keys2.length) {
//     return false;
//   }

//   for (let key of keys1) {
//     if (
//       !keys2.includes(key) ||
//       !deepEqual(trimString(obj1[key]), trimString(obj2[key]))
//     ) {
//       return false;
//     }
//   }

//   return true;
// }

// /**
//  * Удаляет пробелы в начале и конце строки.
//  *
//  * @param {any} value - Значение для обработки.
//  * @returns {any} - Возвращает обработанное значение.
//  */
// function trimString(value) {
//   return typeof value === "string" ? value.trim() : value;
// }

// //////////////////////////////////

// // возвращает варианты ответа
// export function toolOptions(dataTools, column) {
//   return [];
// }

// //////////////////////////////////

// // проверка инструмента на наличие дублирования
// export function toolDuplicationСheck(dataTools, column) {
//   return [];
// }
