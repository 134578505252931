import { useEffect } from "react";

export function UseCtrlZButton(
  setFilter,
  setPanel,
  setDataToolsCopy,
  setIsHoveredFilter,
  SaveFilterCookies,
  dataTools
) {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        event.altKey &&
        (event.key.toLowerCase() === "z" || event.key.toLowerCase() === "я")
      ) {
        setFilter(() => ({
          registration_date: "",
          email: "",
          status: "",
          count: "",
          used_count: "",
          about_history: "",
          brand: "",
          tool_name: "",
          type_tool: "",
          construction: "",
          about_tool: "",
          diameter: "",
          length: "",
          processing_material: "",
        }));
        setPanel((prev) => ({
          ...prev,
          isActivDay: false,
          isActivHour: false,
        }));
        setDataToolsCopy(dataTools);
        setIsHoveredFilter(false);
        SaveFilterCookies("");
      }
    };
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [dataTools]);
}
