import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import React from "react";

import "./App.css";
import Header from "./components/header/Header";
import TabsSection from "./components/TabsSection";
import ToolsPage from "./pages/Tools";
import InsertsPage from "./pages/Inserts";
import IHistoryPage from "./pages/IHistory";
import THistoryPage from "./pages/THistory";
import LogoutPage from "./pages/Logout";
import SinginPage from "./pages/Signin";
import SingupPage from "./pages/Singup";

function App() {
  
  // Вывод всех переменных окружения
  // console.log(process.env);

  return (
    <>
      <Header></Header>

      <Router>
        <TabsSection />
        <Routes>
          <Route path="/tools/" element={<ToolsPage />} />
          <Route path="/inserts/" element={<InsertsPage />} />
          <Route path="/thistory/" element={<THistoryPage />} />
          <Route path="/ihistory/" element={<IHistoryPage />} />
          <Route path="/logout/" element={<LogoutPage />} />
          <Route path="/singin/" element={<SinginPage />} />
          <Route path="/singup/" element={<SingupPage />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;

// npm start

// https://youtu.be/VY8thJoSw9E?si=O2Lom0Olx7Ow_p0i&t=328
// https://youtu.be/kz23xxukY5s?si=G5sbIT2quh1r-17T&t=12142

// npx create-react-app my-app
// npm install ajv
// npm install react-router-dom
// npm install date-fns
// npm install styled-components
// npm install react-helmet-async
// npm install uuid
// npm install dotenv
