import GetCookie from "../../../utils/coockies";
import { BaseAPIUrl } from "../../../config/config";

/**
 * Обновляет информацию о инструменте на сервере.
 *
 * @param {Object} tool - Объект, содержащий данные инструмента.
 * @param {string} tool.id - Идентификатор инструмента.
 * @param {string} tool.brand - Бренд инструмента.
 * @param {string} tool.tool_name - Название инструмента.
 * @param {string} tool.type_tool - Тип инструмента.
 * @param {string} tool.construction - Конструкция инструмента.
 * @param {number} tool.count - Общее количество инструмента.
 * @param {number} tool.used_count - Количество использованных инструментов.
 * @param {number} tool.need_count - Необходимое количество инструментов.
 * @param {string} tool.about_tool - Описание инструмента.
 * @param {string} tool.diameter - Диаметр инструмента.
 * @param {number} tool.cutting_length - Длина режущей части инструмента.
 * @returns {Promise<Array>} Массив, содержащий ошибку (если есть) и данные ответа.
 */
export async function queryUpdateTools(tool) {
  const token = GetCookie("token");
  let err = null;
  let responseData = null;

  try {
    const params = new URLSearchParams({
      id: tool.id,
      brand: tool.brand.trim(),
      tool_name: tool.tool_name.trim(),
      type_tool: tool.type_tool.trim(),
      construction: tool.construction.trim(),
      count: tool.count,
      used_count: tool.used_count,
      need_count: tool.need_count,
      about_tool: tool.about_tool.trim(),
      diameter: tool.diameter.trim(),
      cutting_length: tool.cutting_length,
      processing_material: tool.processing_material.trim(),
    });

    const url = `${BaseAPIUrl}api/tool/?${params.toString()}`;
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Token: token,
      },
    });

    const data = response.ok ? await response.json() : null;

    switch (response.status) {
      case 200:
        err = data.err;
        for (const row of data.tools) {
          responseData = row;
        }
        break;
      case 401:
        err = "пользователь неавторизирован";
        break;
      case 400:
        err = "неправильный запрос";
        break;
      case 500:
        err = "ошибка сервера";
        break;
      default:
        err = `код ответа: ${response.status}`;
    }
  } catch (error) {
    err = error;
  }

  return [err, responseData];
}
