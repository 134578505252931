import { v4 as uuidv4 } from "uuid";

/**
 * Компонент формы для ввода данных о инструменте.
 *
 * @param {Object} data - Объект с состоянием формы, опциями и обработчиком изменений.
 * @param {Object} data.formState - Состояние формы.
 * @param {string} data.formState.brand - Производитель.
 * @param {string} data.formState.tool_name - Маркировка.
 * @param {string} data.formState.type_tool - Тип инструмента.
 * @param {string} data.formState.construction - Конструкция.
 * @param {string} data.formState.count - Количество.
 * @param {string} data.formState.used_count - Использованное количество.
 * @param {string} data.formState.need_count - Необходимое количество.
 * @param {string} data.formState.about_tool - Описание инструмента.
 * @param {string} data.formState.diameter - Диаметр.
 * @param {string} data.formState.cutting_length - Длина.
 * @param {string} data.formState.processing_material - Обрабатываемый материал.
 * @param {boolean} data.formState.hasErrorValodation - Ошибка валидации.
 * @param {boolean} data.formState.hasErrorToolname - Ошибка в поле "Маркировка".
 * @param {boolean} data.formState.hasErrorDiameter - Ошибка в поле "Диаметр".
 * @param {boolean} data.formState.hasErrorLength - Ошибка в поле "Длина".
 * @param {boolean} data.formState.hasErrorTypeTool - Ошибка в поле "Тип инструмента".
 * @param {Object} data.optionsState - Состояние опций для полей формы.
 * @param {Array} data.optionsState.brand - Опции для поля "Производитель".
 * @param {Array} data.optionsState.tool_name - Опции для поля "Маркировка".
 * @param {Array} data.optionsState.type_tool - Опции для поля "Тип инструмента".
 * @param {Array} data.optionsState.construction - Опции для поля "Конструкция".
 * @param {Array} data.optionsState.count - Опции для поля "Количество".
 * @param {Array} data.optionsState.used_count - Опции для поля "Использованное количество".
 * @param {Array} data.optionsState.need_count - Опции для поля "Необходимое количество".
 * @param {Array} data.optionsState.about_tool - Опции для поля "Описание инструмента".
 * @param {Array} data.optionsState.diameter - Опции для поля "Диаметр".
 * @param {Array} data.optionsState.cutting_length - Опции для поля "Длина".
 * @param {Array} data.optionsState.processing_material - Опции для поля "Обрабатываемый материал".
 * @param {function} data.handle - Обработчик изменений в полях формы.
 */
export default function ToolForm(data) {
  return (
    <>
      <form className="update_form">
        <ul>
          <li key="brand">
            <label htmlFor="brand">Производитель</label>
            <input
              type="text"
              id="brand"
              value={data.formState.brand}
              onChange={data.handle}
              list="brands"
            />
            <datalist id="brands">
              {data.optionsState.brand.map((row) => (
                <option key={uuidv4()} value={row}></option>
              ))}
            </datalist>
          </li>
          <li key="tool_name">
            <label htmlFor="tool_name">Маркировка</label>
            <input
              type="text"
              id="tool_name"
              value={data.formState.tool_name}
              onChange={data.handle}
              className={` ${data.formState.hasErrorToolname ? "error" : ""}`}
              list="tool_names"
            />
            <datalist id="tool_names">
              {data.optionsState.tool_name.map((row) => (
                <option key={uuidv4()} value={row}></option>
              ))}
            </datalist>
          </li>
          <li key="type_tool">
            <label htmlFor="type_tool">Тип инструмента</label>
            <input
              type="text"
              id="type_tool"
              value={data.formState.type_tool}
              onChange={data.handle}
              className={` ${data.formState.hasErrorTypeTool ? "error" : ""}`}
              list="type_tools"
            />
            <datalist id="type_tools">
              {data.optionsState.type_tool.map((row) => (
                <option key={uuidv4()} value={row}></option>
              ))}
            </datalist>
          </li>
          <li key="construction">
            <label htmlFor="construction">Конструкция</label>
            <input
              type="text"
              id="construction"
              value={data.formState.construction}
              onChange={data.handle}
              list="constructions"
            />
            <datalist id="constructions">
              {data.optionsState.construction.map((row) => (
                <option key={uuidv4()} value={row}></option>
              ))}
            </datalist>
          </li>
          <li key="count">
            <label htmlFor="count">Количество</label>
            <input
              id="count"
              type="number"
              step="1"
              min="0"
              max="200"
              value={data.formState.count}
              onChange={data.handle}
            />
          </li>
          <li key="used_count">
            <label htmlFor="used_count">Количество Б/У</label>
            <input
              id="used_count"
              type="number"
              step="1"
              min="0"
              max="200"
              value={data.formState.used_count}
              onChange={data.handle}
            />
          </li>
          <li key="need_count">
            <label htmlFor="need_count">Необходимое количество</label>
            <input
              id="need_count"
              type="number"
              step="1"
              min="0"
              max="200"
              value={data.formState.need_count}
              onChange={data.handle}
            />
          </li>
          <li key="about_tool">
            <label htmlFor="about_tool">Описание</label>
            <input
              type="text"
              id="about_tool"
              value={data.formState.about_tool}
              onChange={data.handle}
            />
          </li>
          <li key="diameter">
            <label htmlFor="diameter">Диаметр</label>
            <input
              type="text"
              id="diameter"
              value={data.formState.diameter}
              onChange={data.handle}
              className={` ${data.formState.hasErrorDiameter ? "error" : ""}`}
              list="diameters"
            />
            <datalist id="diameters">
              {data.optionsState.diameter.map((row) => (
                <option key={uuidv4()} value={row}></option>
              ))}
            </datalist>
          </li>
          <li key="cutting_length">
            <label htmlFor="cutting_length">Длина режущей кромки</label>
            <input
              id="cutting_length"
              type="number"
              step="0.1"
              min="0"
              max="300"
              value={data.formState.cutting_length}
              onChange={data.handle}
              className={` ${data.formState.hasErrorLength ? "error" : ""}`}
            />
          </li>
          <li key="processing_material">
            <label htmlFor="processing_material">Материал обработки</label>
            <input
              type="text"
              id="processing_material"
              value={data.formState.processing_material}
              onChange={data.handle}
              list="processing_materials"
            />
            <datalist id="processing_materials">
              {data.optionsState.processing_material.map((row) => (
                <option key={uuidv4()} value={row}></option>
              ))}
            </datalist>
          </li>
        </ul>
      </form>
    </>
  );
}
