import { useEffect, useState } from "react";
import "./Notification.css";

export default function Notification({ children, dataNotification }) {
  let color = dataNotification.color;
  if (color !== "red" && color !== "yellow" && color !== "green") {
    color = "red";
  }

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (dataNotification.id) {
      setVisible(true);
      const timer = setTimeout(() => {
        setVisible(false);
      }, 5000); // Уведомление будет видно 5 секунд

      return () => clearTimeout(timer); // Очистка таймера при размонтировании компонента
    }
  }, [dataNotification.id, dataNotification.children]);

  return (
    <>{visible && <div className={`notification ${color}`}>{children}</div>}</>
  );
}
