import { Helmet, HelmetProvider } from "react-helmet-async";
import { FetchTools } from "../modules/tools/index";

export default function ToolsPage() {
  return (
    <section>
      <HelmetProvider>
        <Helmet>
          <title>список инструмента</title>
        </Helmet>
      </HelmetProvider>

      <FetchTools />
    </section>
  );
}
