// export const BaseAPIUrl = "http://localhost:3003/";
// export const BaseUrl = "http://localhost:5001/";

export const REACT_APP_API_HOST = process.env.REACT_APP_API_HOST
  ? process.env.REACT_APP_API_HOST
  : "localhost";

export const REACT_APP_WEB_HOST = process.env.REACT_APP_WEB_HOST
  ? process.env.REACT_APP_WEB_HOST
  : "localhost";

// export const BaseAPIUrl = `https://${REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/`;
export const BaseAPIUrl = `https://${REACT_APP_API_HOST}/`;
export const BaseWEBUrl = `http://${REACT_APP_WEB_HOST}:${process.env.REACT_APP_WEB_PORT}/`;

export const BaseWEBUrl2 = `https://${REACT_APP_WEB_HOST}/`;
