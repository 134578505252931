import GetCookie from "../../../utils/coockies";

export function SaveFilterCookies(filters) {
  const jsonString = JSON.stringify(filters);
  document.cookie = `insert_filters=${jsonString}; path=/;`;
}

export function GetFilterCookies() {
  const jsonFilters = GetCookie("insert_filters");
  let filters;
  const defaultFilters = {
    brand: "",
    insert_name: "",
    type_insert: "",
    count: "",
    used_count: "",
    need_count: "",
    about_insert: "",
    radius: "",
    cutting_length: "",
    processing_material: "",
  };

  if (jsonFilters !== null && jsonFilters !== "") {
    filters = JSON.parse(jsonFilters);

    if (filters.brand === undefined) {
      filters = defaultFilters;
    }
  } else {
    filters = defaultFilters;
  }

  return filters;
}

export function SaveScrollCookies(position) {
  document.cookie = `insert_scroll=${position}; path=/;`;
}

export function GetScrollCookies() {
  const jsonScroll = GetCookie("insert_scroll");
  const scroll = JSON.parse(jsonScroll);
  return scroll;
}
