import { useEffect } from "react";
// import { v4 as uuidv4 } from "uuid";

export function UseEscButton(setModal, setModalAdd) {
//   console.log("---some---", uuidv4());

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setModal(false);
        setModalAdd(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
}
